import request from '@/utils/request'

const houseSlidesApi = {
  houseSlides: '/api/v1/rest/houseSlides',
  deleteById: '/api/v1/rest/houseSlides/search/deleteByIds',
  findById: '/api/v1/rest/houseSlides/search/findByHouseId',
  editSlideMedia: '/api/v1/houseSlider/edit'
}

/**
 * 
 * @param params { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function houseSlides(params) {
  let url = houseSlidesApi.houseSlides
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

/**
 * 
 * @param data { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function addhouseSlide(data) {
  return request({
    url: houseSlidesApi.houseSlides,
    method: 'post',
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function gethouseSlide(id) {
  return request({
    url: houseSlidesApi.houseSlides + `/${id}`,
    method: 'get'
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function puthouseSlide(id, data) {
  return request({
    url: houseSlidesApi.houseSlides + `/${id}`,
    method: 'put',
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function patchhouseSlide(id, data) {
  return request({
    url: houseSlidesApi.houseSlides + `/${id}`,
    method: 'patch',
    data
  })
}

/**
 * 
 * @param id 
 * @returns {*}
 */
export function delhouseSlide(id) {
  return request({
    url: houseSlidesApi.houseSlides + `/${id}`,
    method: 'delete'
  })
}

/**
 * 
 * @param { ids: [] } 
 * @returns {*}
 */
export function deleteByIdsHouseSlide(param) {
  let url = houseSlidesApi.deleteById
  url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

/**
 * 
 * @param { houseId: '' } 
 * @returns {*}
 */
export function findByHouseIdHouseSlide(param) {
  console.log(param, 'param');
  let url = houseSlidesApi.findById
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  console.log(url, 'url');
  return request({
    url,
    method: 'get'
  })
}

/**
 * 
 * @data { house: '', medias: '' } 
 * @returns {*}
 */
export function editHouseSlideMedia(data) {
  return request({
    url: houseSlidesApi.editSlideMedia,
    method: 'post',
    data
  })
}
