<template>
  <div>
    <a-spin :spinning="false">
      <a-row>
        <span>
          <media v-for="file in media" :key="file.id" :file="file" :img="true" />
        </span>
      </a-row>
      <a-row>
        <a-space :size="15">
          <a-button @click="showModal('set')">设置</a-button>
          <a-button type="dashed" @click="showModal('sort')">排序</a-button>
        </a-space>
      </a-row>
    </a-spin>

    <drawer-form
      ref="createForm"
      :title="this.noticeTitle"
      :visible="visible"
      :loading="slideLoading"
      @ok="handleSubmit('set')"
      @cancle="hideModal('set')"
      @emitMedia="getMedia"
      @emitLoading="getLoading"
    ></drawer-form>

    <a-modal
      :width="800"
      centered
      :title="this.noticeTitle"
      :visible="sortVisible"
      @ok="handleSubmit('srot')"
      @cancel="hideModal('sort')"
    >
      <template slot="footer">
        <span class="sortDescription">上下拖拽附件进行排序</span>
        <a-button key="back" @click="hideModal('sort')">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleSubmit('sort')">
          确定
        </a-button>
      </template>
      <a-spin :spinning="sortLoading">
        <draggable v-model="sortList" @end="endEvent" @sort="sortEvent" v-bind="dragOptions">
          <a-row v-for="file in sortList" :key="file.id" style="margin-bottom: 15px;">
            <media :file="file" :width="imgWidth" :img="true"></media></a-row>
        </draggable>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { findByHouseIdHouseSlide, editHouseSlideMedia } from '@/api/houseSlides'
import DrawerForm from './CreateSlideForm'
import { Media } from '@/components'
export default {
  components: {
    DrawerForm,
    Media,
    draggable
  },
  data() {
    return {
      noticeTitle: '轮播图管理',
      imgWidth: 150,
      dragOptions: {
        animation: 500,
        filter: '.disabled'
      },
      houseId: null,
      houseLink: null,
      media: [],
      sortList: [],
      slideMedia: [],
      mediaDesign: [],
      mediaArea: [],
      mediaBuilding: [],
      mediaScene: [],
      mediaSocial: [],
      mediaSale: [],
      mediaSand: [],
      slideLoading: false,
      spinning: false,
      visible: false,
      sortVisible: false,
      sortLoading: false
    }
  },
  mounted() {
    this.getSlideMedia()
  },
  computed: {
    host() {
      return this.$store.state.oss.host
    },
    serial() {
      return this.$store.state.env.serial
    }
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  methods: {
    showModal(type) {
      switch (type) {
        case 'set':
          this.$refs.createForm.getAllMedia()
          this.visible = true
          break
        case 'sort':
          this.sortList = this.media
          if (this.sortList.length === 0) {
            this.$notification.warning({
              message: this.noticeTitle,
              description: '请先设置轮播图'
            })
            return
          }
          this.sortVisible = true
          break
      }
    },
    hideModal(type) {
      switch (type) {
        case 'set':
          this.visible = false
          break
        case 'sort':
          this.sortVisible = false
      }
    },
    handleSubmit(type, msg = '设置成功') {
      switch (type) {
        case 'set':
          this.loadHandle(this.slideMedia, msg)
          break
        case 'sort':
          // this.slideMedia = this.sortList
          this.sortLoading = true
          msg = '排序成功'
          console.log(this.sortList, 'slideMedia')
          this.loadHandle(this.sortList, msg)
          break
      }
    },
    getMedia(data) {
      console.log(data, 'data')
      this.slideMedia = data
    },
    getLoading(data) {
      this.slideLoading = data
    },
    getSlideMedia() {
      const { houseId } = this
      findByHouseIdHouseSlide({ houseId }).then(res => {
        const list = res._embedded.houseSlides
        this.media = this.formatFileList(list)
        console.log(this.media, 'list')
      })
    },
    loadHandle(arr, msg) {
      this.slideLoading = true
      const newArr = []
      arr.forEach((item, index) => {
        const obj = {
          sort: index,
          mediaId: item.uid
        }
        newArr.push(obj)
      })
      const param = {
        houseId: this.houseId,
        medias: newArr
      }
      editHouseSlideMedia(param)
        .then(res => {
          this.visible = false
          this.sortVisible = false

          this.endPerform()
          this.$notification.success({
            message: this.noticeTitle,
            description: msg
          })
        })
        .catch(() => {
          this.endPerform()
        })
    },
    endPerform() {
      this.slideLoading = false
      this.sortLoading = false
      this.getSlideMedia()
    },
    formatFileList(list) {
      const newList = []
      list.forEach(child => {
        const item = child.media
        const obj = {
          uid: item.id,
          name: item.path.slice(item.path.lastIndexOf('/') + 1),
          status: 'done',
          path: item.path,
          sort: child.sort
        }
        const path = `${this.host}${item.path}`
        if (item.type === 'video/mp4') {
          obj.url = `${path}${this.THUMBNAIL_VEDIOCOVER}`
          obj.src = `${path}`
        } else {
          obj.url = `${path}${this.THUMBNAIL_ORIGIN}`
        }
        newList.push(obj)
      })
      return newList.sort((a, b) => { 
        return a.sort - b.sort 
      })
    },
    sortEvent() {
      this.sortLoading = true
    },
    endEvent() {
      this.sortLoading = false
      console.log(this.sortList)
    }
  }
}
</script>
