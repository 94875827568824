<template>
  <div>
    <a-card class="card" title="轮播图设置" :bordered="false">
      <house-slide></house-slide>
    </a-card>
    <a-card class="card" title="项目效果图" :bordered="false">
      <house-medias subName="mediaDesign" />
    </a-card>
    <a-card class="card" title="区位图" :bordered="false">
      <house-medias subName="mediaArea" />
    </a-card>
    <a-card class="card" title="楼栋图" :bordered="false">
      <house-medias subName="mediaBuilding" />
    </a-card>
    <a-card class="card" title="项目实景图" :bordered="false">
      <house-medias subName="mediaScene" />
    </a-card>
    <a-card class="card" title="小区配套" :bordered="false">
      <house-medias subName="mediaSocial" />
    </a-card>
    <a-card class="card" title="售楼处" :bordered="false">
      <house-medias subName="mediaSale" />
    </a-card>
    <a-card class="card" title="沙盘图" :bordered="false">
      <house-medias subName="mediaSand" />
    </a-card>
  </div>
</template>

<script>
import HouseMedias from './upload/HouseMedias'
import HouseSlide from './modules/HouseSlide'
import { baseMixin } from '@/store/app-mixin'

export default {
  name: 'AdvancedForm',
  mixins: [baseMixin],
  components: {
    HouseMedias,
    HouseSlide
  },
  data() {
    return {
      houseId: '',
      houseLink: '',
      loading: false,
      memberLoading: false,
      errors: []
    }
  },
  computed: {
    host() {
      return this.$store.state.oss.host
    }
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
  /deep/ .ant-card-body {
    padding-bottom: 0;
  }
}
.popover-wrapper {
  /deep/ .antd-pro-pages-forms-style-errorPopover .ant-popover-inner-content {
    min-width: 256px;
    max-height: 290px;
    padding: 0;
    overflow: auto;
  }
}
.antd-pro-pages-forms-style-errorIcon {
  user-select: none;
  margin-right: 24px;
  color: #f5222d;
  cursor: pointer;
  i {
    margin-right: 4px;
  }
}
.antd-pro-pages-forms-style-errorListItem {
  padding: 8px 16px;
  list-style: none;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #e6f7ff;
  }
  .antd-pro-pages-forms-style-errorIcon {
    float: left;
    margin-top: 4px;
    margin-right: 12px;
    padding-bottom: 22px;
    color: #f5222d;
  }
  .antd-pro-pages-forms-style-errorField {
    margin-top: 2px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
}
</style>
