<template>
  <div>
    <a-drawer
      title="Create a new account"
      :width="1000"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="drawerCancle"
    >
      <a-spin :spinning="loading">
        <div>
          <a-card class="card" title="项目效果图" :bordered="false">
            <span class="wrapper" v-for="file in mediaDesign" :key="file.id">
              <a-checkbox
                class="myCheckbox"
                :value="`${file.type},${file.uid}`"
                @change="onchange"
                :checked="file.checked"
              ></a-checkbox>
              <media :file="file" :img="true" />
            </span>
            <span class="wrapper" v-if="mediaDesign.length === 0">暂无图片</span>
          </a-card>
          <a-card class="card" title="区位图" :bordered="false">
            <span class="wrapper" v-for="file in mediaArea" :key="file.id">
              <a-checkbox
                class="myCheckbox"
                :value="`${file.type},${file.uid}`"
                @change="onchange"
                :checked="file.checked"
              ></a-checkbox>
              <media :file="file" :img="true" />
            </span>
            <span class="wrapper" v-if="mediaArea.length === 0">暂无图片</span>
          </a-card>
          <a-card class="card" title="楼栋图" :bordered="false">
            <span class="wrapper" v-for="file in mediaBuilding" :key="file.id">
              <a-checkbox
                class="myCheckbox"
                :value="`${file.type},${file.uid}`"
                @change="onchange"
                :checked="file.checked"
              ></a-checkbox>
              <media :file="file" :img="true" />
            </span>
            <span class="wrapper" v-if="mediaBuilding.length === 0">暂无图片</span>
          </a-card>
          <a-card class="card" title="项目实景图" :bordered="false">
            <span class="wrapper" v-for="file in mediaScene" :key="file.id">
              <a-checkbox
                class="myCheckbox"
                :value="`${file.type},${file.uid}`"
                @change="onchange"
                :checked="file.checked"
              ></a-checkbox>
              <media :file="file" :img="true" />
            </span>
            <span class="wrapper" v-if="mediaScene.length === 0">暂无图片</span>
          </a-card>
          <a-card class="card" title="小区配套" :bordered="false">
            <span class="wrapper" v-for="file in mediaSocial" :key="file.id">
              <a-checkbox
                class="myCheckbox"
                :value="`${file.type},${file.uid}`"
                @change="onchange"
                :checked="file.checked"
              ></a-checkbox>
              <media :file="file" :img="true" />
            </span>
            <span class="wrapper" v-if="mediaSocial.length === 0">暂无图片</span>
          </a-card>
          <a-card class="card" title="售楼处" :bordered="false">
            <span class="wrapper" v-for="file in mediaSale" :key="file.id">
              <a-checkbox
                class="myCheckbox"
                :value="`${file.type},${file.uid}`"
                @change="onchange"
                :checked="file.checked"
              ></a-checkbox>
              <media :file="file" :img="true" />
            </span>
            <span class="wrapper" v-if="mediaSale.length === 0">暂无图片</span>
          </a-card>
          <a-card class="card" title="沙盘图" :bordered="false">
            <span class="wrapper" v-for="file in mediaSand" :key="file.id">
              <a-checkbox
                class="myCheckbox"
                :value="`${file.type},${file.uid}`"
                @change="onchange"
                :checked="file.checked"
              ></a-checkbox>
              <media :file="file" :img="true" />
            </span>
            <span class="wrapper" v-if="mediaSand.length === 0">暂无图片</span>
          </a-card>
        </div>
      </a-spin>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1
        }"
      >
        <a-button
          :style="{ marginRight: '8px' }"
          @click="drawerCancle"
        >
          取消
        </a-button>
        <a-button type="primary" @click="drawerOk" :loading="loading" style="z-index: 2;">
          确认
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { findByHouseIdHouseSlide } from '@/api/houseSlides'
import { getHouseSub } from '@/api/house'
import pick from 'lodash.pick'
import { UploadOss, Media } from '@/components'

// 表单字段
const fields = ['category', 'content']

export default {
  name: 'SlideMedia',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    },
    media: {
      type: Array,
      default: () => []
    }
  },
  components: {
    UploadOss,
    Media
  },
  data() {
    return {
      form: this.$form.createForm(this),
      noticeTitle: '轮播图管理',
      houseId: [],
      houseLink: [],
      mediaDesign: [],
      mediaArea: [],
      mediaBuilding: [],
      mediaScene: [],
      mediaSocial: [],
      mediaSale: [],
      mediaSand: [],
      houseSlide: []
    }
  },
  computed: {
    host() {
      return this.$store.state.oss.host
    }
  },
  mounted() {},
  methods: {
    getFileList(data) {
      // this.media = data
      this.$emit('emitMedia', data)
    },
    getSlideMedia() {
      const { houseId } = this
      findByHouseIdHouseSlide({ houseId })
        .then(res => {
          console.log(res, 'getSlideMedia')
          const list = res._embedded.houseSlides
          const setCheched = (arr, item) => {
            arr.forEach(child => {
              if (child.uid === item.uid) {
                child.checked = true
              }
            })
          }
          this.houseSlide = this.formatFileList(list, 'houseSlides', 'slide').map(item => {
            console.log(this.mediaSand, 'getSlideMedia map')
            const value = item.path
            if (value.indexOf('mediaDesign') !== -1) {
              setCheched(this.mediaDesign, item)
            } else if (value.indexOf('mediaArea') !== -1) {
              setCheched(this.mediaArea, item)
            } else if (value.indexOf('mediaBuilding') !== -1) {
              setCheched(this.mediaBuilding, item)
            } else if (value.indexOf('mediaScene') !== -1) {
              setCheched(this.mediaScene, item)
            } else if (value.indexOf('mediaSocial') !== -1) {
              setCheched(this.mediaSocial, item)
            } else if (value.indexOf('mediaSale') !== -1) {
              setCheched(this.mediaSale, item)
            } else if (value.indexOf('mediaSand') !== -1) {
              setCheched(this.mediaSand, item)
            }
          })
          this.$emit('emitLoading', false)
        })
    },
    getAllMedia() {
      this.getHouseMedia('mediaDesign')
      this.getHouseMedia('mediaArea')
      this.getHouseMedia('mediaBuilding')
      this.getHouseMedia('mediaScene')
      this.getHouseMedia('mediaSocial')
      this.getHouseMedia('mediaSale')
      this.getHouseMedia('mediaSand')
      this.getSlideMedia()
    },
    getHouseMedia(type) {
      const { houseId } = this
      getHouseSub(houseId, type)
        .then(res => {
          const list = res._embedded.medias
          switch (type) {
            case 'mediaDesign':
              this.mediaDesign = this.formatFileList(list, 'mediaDesign')
              console.log(this.mediaDesign, 'this.this.mediaDesign ')
              break
            case 'mediaArea':
              this.mediaArea = this.formatFileList(list, 'mediaArea')
              break
            case 'mediaBuilding':
              this.mediaBuilding = this.formatFileList(list, 'mediaBuilding')
              break
            case 'mediaScene':
              this.mediaScene = this.formatFileList(list, 'mediaScene')
              break
            case 'mediaSocial':
              this.mediaSocial = this.formatFileList(list, 'mediaSocial')
              break
            case 'mediaSale':
              this.mediaSale = this.formatFileList(list, 'mediaSale')
              break
            case 'mediaSand':
              this.mediaSand = this.formatFileList(list, 'mediaSand')
              break
          }
        })
    },
    onchange(e) {
      const { checkedArr, mediaDesign, mediaArea, mediaBuilding, mediaScene, mediaSocial, mediaSale, mediaSand } = this
      const value = e.target.value
      const checked = e.target.checked
      const arr = value.split(',')
      const type = arr[0]
      const id = arr[1]
      switch (type) {
        case 'mediaDesign':
          checkedArr(mediaDesign, id, checked)
          break
        case 'mediaArea':
          checkedArr(mediaArea, id, checked)
          break
        case 'mediaBuilding':
          checkedArr(mediaBuilding, id, checked)
          break
        case 'mediaScene':
          checkedArr(mediaScene, id, checked)
          break
        case 'mediaSocial':
          checkedArr(mediaSocial, id, checked)
          break
        case 'mediaSale':
          checkedArr(mediaSale, id, checked)
          break
        case 'mediaSand':
          checkedArr(mediaSand, id, checked)
          break
      }
    },
    checkedArr(arr, id, checked) {
      arr.forEach(item => {
        if (item.uid === id) {
          item.checked = checked
        }
      })
    },
    drawerOk() {
      const newArr = this.fetchNewArr()
      this.$emit('emitMedia', newArr)
      this.$emit('ok')
    },
    drawerCancle() {
      this.$emit('cancle')
    },
    fetchNewArr() {
      const newArr = []
      function getChecked(arr) {
        arr.forEach(item => {
          if (item.checked) {
            newArr.push(item)
          }
        })
      }
      getChecked(this.mediaDesign)
      getChecked(this.mediaArea)
      getChecked(this.mediaBuilding)
      getChecked(this.mediaScene)
      getChecked(this.mediaSocial)
      getChecked(this.mediaSale)
      getChecked(this.mediaSand)
      return newArr
    },
    formatFileList(list, type, status = 'normal') {
      const newList = []
      list.forEach(item => {
        if (status === 'slide') {
          item = item.media
        }
        const name = item.path.slice(item.path.lastIndexOf('/') + 1)
        const obj = {
          uid: item.id,
          name,
          status: 'done',
          path: item.path,
          sort: item.sort,
          checked: false,
          type
        }
        const path = `${this.host}${item.path}`
        if (item.type === 'video/mp4') {
          obj.url = `${path}${this.THUMBNAIL_VEDIOCOVER}`
          obj.src = `${path}`
        } else {
          obj.url = `${path}${this.THUMBNAIL_ORIGIN}`
        }
        newList.push(obj)
      })
      return newList
    },
    nortifaction(message = '提醒信息', description = '请注意。', type = 'success') {
      this.$notification[type]({
        message,
        description
      })
    }
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>

<style lang="less" scoped>
.card {
  .wrapper {
    position: relative;
    display: inline-block;
    .myCheckbox {
      position: absolute;
      right: 16px;
      top: 6px;
      z-index: 2;
    }
  }
}
</style>
