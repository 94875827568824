<template>
  <div>
    <a-spin :spinning="spinning" :tip="tip">
      <div>
        <a-row>
          <uploadOss :media="media" @emitFiles="getFileList" :maxLength="maxLength"></uploadOss>
        </a-row>
        <a-row>
          <a-space :size="15">
            <a-button @click="showModal('upload')">提交</a-button>
            <a-button @click="showModal('sort')" type="dashed">排序</a-button>
          </a-space>
        </a-row>
      </div>
    </a-spin>

    <a-modal
      :title="this.noticeTitle"
      centered
      :visible="uploadVisible"
      @ok="handleSubmit('upload')"
      @cancel="hideModal('upload')"
    >
      <p>确认上传附件?</p>
    </a-modal>

    <a-modal
      :width="800"
      centered
      :title="this.noticeTitle"
      :visible="sortVisible"
      @ok="handleSubmit('sort')"
      @cancel="hideModal('sort')"
    >
      <template slot="footer">
        <span class="sortDescription">上下拖拽附件进行排序</span>
        <a-button key="back" @click="hideModal('sort')">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleSubmit('sort')">
          确定
        </a-button>
      </template>
      <a-spin :spinning="sortLoading">
        <draggable v-model="sortList" @end="endEvent" @sort="sortEvent" v-bind="dragOptions">
          <a-row v-for="file in sortList" :key="file.id" style="margin-bottom: 15px;">
            <media :file="file" :width="imgWidth" :card="true"></media>
          </a-row>
        </draggable>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { uploadOssBatch } from '@/utils/upload'
import { bindHouse, getHouseSub } from '@/api/house'
import { UploadOss, Media } from '@/components'
export default {
  name: 'RepositoryForm',
  props: {
    subName: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 9
    }
  },
  components: {
    UploadOss,
    draggable,
    Media
  },
  data() {
    return {
      dragOptions: {
        animation: 500,
        filter: '.disabled'
      },
      houseId: null,
      houseLink: '',
      media: [],
      spinning: false,
      uploadVisible: false,
      sortVisible: false,
      sortLoading: false,
      tip: '上传中，如果文件过大，或者文件数量较多时，上传时间会变长...',
      imgWidth: 150,
      sortList: [],
      noticeTitle: '附件管理'
    }
  },
  mounted() {
    this.getMedia()
  },
  computed: {
    host() {
      return this.$store.state.oss.host
    },
    serial() {
      return this.$store.state.env.serial
    }
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  methods: {
    showModal(val) {
      console.log(val)
      switch (val) {
        case 'sort':
          const { media } = this
          if (media.length === 0) {
            this.$notification.warning({
              message: this.noticeTitle,
              description: '请先选择附件'
            })
            return
          }
          this.sortList = this.media
          this.sortVisible = true
          break
        case 'upload':
          this.uploadVisible = true
          break
        default:
          this.sortVisible = true
          this.uploadVisible = true
          break
      }
    },
    hideModal(val) {
      switch (val) {
        case 'sort':
          this.sortVisible = false
          break
        case 'upload':
          this.uploadVisible = false
          break
        default:
          this.sortVisible = false
          this.uploadVisible = false
          break
      }
    },
    getMedia() {
      const { houseId, subName, maxLength } = this
      getHouseSub(houseId, subName).then(res => {
        const list = res._embedded.medias
        if (maxLength === 1) {
          this.media = this.formatFileList(list) // [this.formatFileList(list)[0]]
        } else {
          this.media = this.formatFileList(list)
        }
        this.sortList = []
      })
    },
    formatFileList(list) {
      const newList = []
      list.forEach(item => {
        const obj = {
          uid: item.id,
          name: item.path.slice(item.path.lastIndexOf('/') + 1),
          status: 'done',
          path: item.path,
          sort: item.sort
        }
        const path = `${this.host}${item.path}`
        if (item.type === 'video/mp4') {
          obj.url = `${path}${this.THUMBNAIL_VEDIOCOVER}`
          obj.src = `${path}`
        } else {
          obj.url = `${path}${this.THUMBNAIL_ORIGIN}`
        }
        newList.push(obj)
      })
      return newList
    },
    getFileList(data) {
      this.media = data
    },
    handleSubmit(val) {
      let msg = ''
      switch (val) {
        case 'sort':
          msg = '排序成功'
          this.tip = '排序中，如果文件过大，或者文件数量较多时，排序时间会变长...'
          this.media = this.sortList
          break
        case 'upload':
          msg = '上传成功'
          break
      }
      this.loadHandle(msg)
    },
    loadHandle(msg) {
      this.uploadVisible = false
      this.sortVisible = false
      const { media, subName, serial, houseId } = this
      this.spinning = true
      const mediaList = media.map(item => {
        if (item.originFileObj === undefined) {
          return item
        } else {
          return item.originFileObj
        }
      })
      uploadOssBatch(mediaList, `/${subName}`, true).then(mediaRes => {
        if (mediaRes) {
          console.log(mediaRes, 'mediaRes')
          const urls = mediaRes
            .map(item => {
              if (item._links) {
                return item._links.self.href
              } else {
                return serial + '/rest/medias/' + item.uid
              }
            })
            .join('\n')
          const request = {
            houseId,
            requestName: subName,
            method: 'put',
            data: urls
          }
          bindHouse(request).then(bindRes => {
            this.spinning = false
            this.$notification.success({
              message: this.noticeTitle,
              description: msg
            })
            this.getMedia()
          })
        }
      })
    },
    sortEvent() {
      this.sortLoading = true
      console.log(this.sortList) // 自动更新reportList
    },
    endEvent() {
      this.sortLoading = false
      console.log(this.sortList) // 自动更新reportList
    }
  }
}
</script>

<style scoped>
.videoModal {
  height: 600px;
  width: 600px;
}
</style>
